import {lazy, useEffect} from 'react'
import { WINDOW_NAMES } from '../utils/consts'
import {useMainStore} from "../state";

//window name in manifest file

//lazy load window components, so that they are not loaded until they are needed
//this is done to reduce the amount of time spent loading
const BackgroundWindow = lazy(() => import('./BackgroundWindow'))
const DesktopWindow = lazy(() => import('./DesktopWindow'))

interface IWindowPageProps {
  window: string
}
//return the current page based on the window name, the current window name is passed in as a prop
//this is used to determine which page to render
export const CurrentWindow = ({ window }: IWindowPageProps) => {
  switch (window) {
    case WINDOW_NAMES.BACKGROUND:
      return <BackgroundWindow />
    case WINDOW_NAMES.DESKTOP:
        return <DesktopWindow />
    default:
      return <p>Loading ...</p>
  }
}
