import axios from "axios";
import { errorNotification, successNotification, warningNotification } from "./notifications";


interface apiResponse {
    data : any
    status: number;
}

export async function putRequest(path:string, data : any) {
    const response = await axios.put(path,
     data,
     { validateStatus: (status) => (status < 500)}
    ).catch((e) => {
        errorNotification('Whoops! Unexpected error occured', 'Please try again. If this keeps happening, please reach out on Discord!')
        console.log('Unknown put error on', path, JSON.stringify(e));
        return {data: {
                        detail: 'Unexpected error'
                      }, 
                status: 500}
    });

    if (response.status < 300)
        return [response.data, ""]
    else
        return [{}, response.data.detail]
}

export async function postRequest(path:string, data : any, successNotificationTitle?: string | undefined) {
    const response = await axios.post(path,
     data,
     { validateStatus: (status) => (status < 500)}
    ).catch((e) => {
        console.log('Unknown put error on', path, JSON.stringify(e));
        errorNotification('Whoops! Unexpected error occured', 'Please try again. If this keeps happening, please reach out on Discord!')
        return {data: {
                        detail: 'Unexpected error'
                      }, 
                status: 500}
    });

    if (response.status < 300) {
        if (successNotificationTitle) {
            successNotification(successNotificationTitle, '')
        }
        return [response.data, ""]
    }
    else    
        return [{}, response.data.detail]
}