import { useLocalStorage } from "@mantine/hooks";
import axios from "axios";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { notifications } from '@mantine/notifications';
import { UserInfo, loginFormValues } from "../utils/types";
import { successNotification, warningNotification } from "../utils/notifications";
import { putRequest, postRequest } from "../utils/api_utils";
import { useNavigate } from "react-router-dom";


  
interface AuthContext {
    token: string | undefined;
    setToken : Function;
    userInfo : UserInfo;
    setUserInfo : Function;
    requestChangeUserInfo: Function;
    requestChangePassword: Function;
    requestChangeEmail: Function;
    requestForgotPassword: Function;
    requestResetPassword: Function;
    navigateToLogin: Function;
    register : Function;
    login : Function;
    logout : Function;

}

// Create the authentication context
const AuthContext = createContext<AuthContext>({} as AuthContext);

export function AuthProvider({ children } : {children  : ReactNode}) {
    const navigate = useNavigate();
    const [token, setToken, clearToken] = useLocalStorage<string | undefined>({
        key: 'token',
    })

    const [userInfo, setUserInfo, clearUserInfo] = useLocalStorage<any>({
        key: 'userInfo',
        defaultValue: undefined,
        serialize: (value) => (!value ? "" : JSON.stringify(value)),
        deserialize: (value) => (!value ? undefined : JSON.parse(value))
      })
    
    const register = async (formValues : loginFormValues) => {
        const response = await axios.post('api/register', {
              username: formValues.username,
              email: formValues.email,
              password: formValues.password,
          }, { validateStatus: (status) => (status < 500)}
          ).catch((e) => {
              console.log('Unknown registration error', JSON.stringify(e));
              warningNotification('Registration Failed', 
                          'Registration failed due to an unexpected error, please try again later')
              return;
          });
  
          if (response)
              return postLogin(response);
      }
    
    const login = async (formValues : loginFormValues) => {
        console.log('logging in')

        const response = await axios.post('api/login', {
            identifier: formValues.identifier,
            password: formValues.password,
        }, { validateStatus: (status) => (status < 500)}
        ).catch((e) => {
            console.log('Unknown login error', JSON.stringify(e));
            warningNotification('Login Failed', 
                        'Login failed due to an unexpected error, please try again later')
            return;
        });

        if (response)
            return postLogin(response);
    }
    
    const postLogin = (loginResponse: { data: { access_token: any; user_info : any, detail:any}; status: number}) => {
        if (loginResponse.status == 200) {
            setToken(loginResponse.data.access_token)
            setUserInfo(loginResponse.data.user_info)
            successNotification('Logged in successfully', `Hi ${loginResponse.data.user_info.username}!`)
            return ""
        }
        else {
            return loginResponse.data.detail
        }
    }
    
    const logout = () => {
        clearToken()
        localStorage.removeItem('token')
        clearUserInfo()
        successNotification('Logged out successfully', undefined)
        navigate('/')
    }

    const requestChangeUserInfo = async (values : any) => {
        const [data, error] = await putRequest('api/user/change-details', 
                                            {'username': values.username,
                                            'twitchProfile': values.twitchProfile,
                                            'twitterProfile': values.twitterProfile,
                                            'youtubeProfile': values.youtubeProfile,
                                            })
        if (error) {
            warningNotification("Could not update user info", error)
            return false
        }
        else {
            successNotification('Succesfully updated user info!', '')
            setToken(data.access_token)
            setUserInfo(data.user_info)
            return true
        }
    }

    const requestChangeEmail = async (newEmail : string) => {
        const [data, error] = await putRequest('api/user/change-email', 
                                            {'email': newEmail})
        if (error) {
            warningNotification("Could not change email", error)
            return false
        }
        else {
            successNotification('Succesfully changed email', '')
            setToken(data.access_token)
            setUserInfo(data.user_info)
            return true
        }
    }

    const requestChangePassword = async (currentPassword : string, newPassword:string) => {
        const [data, error] = await putRequest('api/user/change-password', 
                                        {'current_password': currentPassword,
                                        'new_password': newPassword})
        if (error) {
            warningNotification("Could not change password", error)
            return false
        }
        else {
            successNotification('Password changed successfully!', '')
            return true
        }
    }

    const requestForgotPassword = async (email : string) => {
        const [data, error] = await postRequest('api/user/forgot-password', 
                                        {'email': email})
        if (error) {
            warningNotification("Could not send reset email", error)
            return false
        }
        else {
            successNotification('Password reset sent!', 'A password reset link has been sent.')
            return true
        }
    }

    const requestResetPassword = async (reset_token:string, email:string, newPassword:string) => {
        const [data, error] = await postRequest('api/user/reset-password', {'email': email, 'reset_token': reset_token, 'new_password': newPassword})
        if (error) {
        warningNotification("Could not change password", error)
        return false
        }
        else {
        successNotification('Password changed succesfully!', 'You can now login with the new password')
        return true
        }
    }
    const navigateToLogin = () => {
        navigate('/login')
        warningNotification('Login Required', 'You need an account to do that! Please login / register.')
    }
    
    const contextValue = useMemo(
        () => ({
          token,
          setToken,
          userInfo,
          setUserInfo,
          requestChangeUserInfo,
          requestChangePassword,
          requestChangeEmail,
          requestForgotPassword,
          requestResetPassword,
          navigateToLogin,
          register,
          login,
          logout
        }),
        [token,
        userInfo]
      );
      
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
      );
}

export const useAuth = () => {
    return useContext(AuthContext);
  };