import { notifications } from "@mantine/notifications";
import { IconX, IconCheck } from '@tabler/icons-react';

export function errorNotification(title:string, message:string) {
    notifications.show({
        'title': title,
        'message': message,
        'color': 'red.6',
        'autoClose':5000
    })
}


export function warningNotification(title:string, message:string) {
    notifications.show({
        'title': title,
        'message': message,
        'color': 'orange.6',
        'autoClose':5000
    })
}

export function successNotification(title:string | undefined, message:string | undefined) {
    notifications.show({
        'title': title,
        'message': message,
        'icon': <IconCheck/>
    })
}
