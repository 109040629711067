import {
    createBrowserRouter,
    createRoutesFromElements,
    Route
} from "react-router-dom";

import loadable from '@loadable/component'

import App from "../App";
import { Page } from "../pages/Page";


const CreateModifyBuildPage = loadable(() => import('../components/CreateModifyBuildPage'))
const StatsPage = loadable(() => import('../pages/StatsPage'));
const HomePage = loadable(() => import('../pages/HomePage'));
const ContactPage = loadable(() => import('../pages/ContactPage'));
const PrivacyPage = loadable(() => import('../pages/PrivacyPage'));
const TermsPage = loadable(() => import('../pages/TermsPage'));
const BuildsViewPage = loadable(() => import('../pages/BuildsViewPage'));
const Login = loadable(() => import('../components/Login'));
const ForgotPassword = loadable(() => import('../components/ForgotPassword'));
const MyAccount = loadable(() => import('../components/MyAccount'));
const ProtectedRoute = loadable(() => import('../components/ProtectedRoute'));
const ResetPasswordPage = loadable(() => import('../components/ResetPasswordPage'));
const UserPage = loadable(() => import('../pages/UserPage'));
const BuildViewerPage = loadable(() => import('../components/BuildViewerPage'));
const PlayerPage = loadable(() => import('../pages/PlayerPage'));
const PlayerSearchPage = loadable(() => import('../pages/PlayerSearchPage'));
const UnitsPage = loadable(() => import('../pages/UnitsPage'));
const LeaderboardPage = loadable(() => import('../pages/LeaderboardPage'));
  
export const createDesktopRouter = () => {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<App/>}>
                    <Route
                        Component={Page}
                    >
                        <Route index Component={HomePage}/>
                    </Route>
                    <Route
                        path='builds'
                        Component={Page}
                    >
                        <Route index Component={BuildsViewPage}/>
                        <Route
                            path='sort/:sort'
                            Component={BuildsViewPage}
                        />
                        <Route
                            path='vs/:enemy_faction/sort?/:sort?'
                            Component={BuildsViewPage}
                        />
                        <Route
                            path='faction/:faction/sort?/:sort?'
                            Component={BuildsViewPage}
                        />
                        <Route
                            path='faction/:faction/vs/:enemy_faction/sort?/:sort?'
                            Component={BuildsViewPage}
                        />
                        <Route
                            path='create'
                            Component={ProtectedRoute}
                        >
                            <Route index Component={CreateModifyBuildPage}/>
                        </Route>
                        <Route
                            path='edit/:build_id'
                            Component={CreateModifyBuildPage}
                        />
                        <Route
                            path=':build_id_name'
                            Component={BuildViewerPage}
                        />

                        {/* Game mode */}
                        <Route
                            path='gameMode/:game_mode/sort?/:sort?'
                            Component={BuildsViewPage}
                        />
                        <Route
                            path='gameMode/:game_mode/faction/:faction/sort?/:sort?'
                            Component={BuildsViewPage}
                        />

                    </Route>
                    <Route
                        path='/stats'
                        Component={Page}
                    >
                        <Route index Component={StatsPage}/>
                    </Route>
                    <Route
                        path='/units'
                        Component={Page}
                    >
                        <Route index Component={UnitsPage}/>
                        <Route
                            path='faction/:faction'
                            Component={UnitsPage}
                        />
                    </Route>
                    <Route 
                        path='/player'
                        Component={Page}>
                            <Route index Component={PlayerPage}/>
                            <Route path=':name' Component={PlayerPage}/>
                    </Route>
                    <Route
                        path='/playerSearch'
                        Component={Page}>
                            <Route path=':search' Component={PlayerSearchPage} />
                        </Route>

                    <Route
                        path='/leaderboard'
                        Component={Page}>
                            <Route index Component={LeaderboardPage} />
                            <Route path=":paramFaction" Component={LeaderboardPage} />
                    </Route>
                    <Route path='/login' Component={Login}/>
                    <Route path='/forgot' Component={ForgotPassword}/>
                    <Route path='/settings' Component={ProtectedRoute}>
                        <Route index Component={MyAccount}/>
                    </Route>
                    <Route path='/resetPassword' Component={ResetPasswordPage}/>
                    <Route path='/users/:user?' Component={Page}>
                        <Route index Component={UserPage}/>
                    </Route>
                    <Route path='/contact' Component={Page}>
                        <Route index Component={ContactPage}/>
                    </Route>
                    <Route path='/terms' Component={Page}>
                        <Route index Component={TermsPage}/>
                    </Route>
                    <Route path='/privacy' Component={Page}>
                        <Route index Component={PrivacyPage}/>
                    </Route>
                </Route>
        ),
        )
}