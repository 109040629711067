import * as React from 'react';
import '@mantine/tiptap/styles.css';
//@ts-ignore
import { createRoot } from 'react-dom/client';
import { createDesktopRouter } from './utils/desktop_router';
import { RouterProvider } from 'react-router-dom';

const router = createDesktopRouter();
const root = createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router}/>);
