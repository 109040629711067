import { PlayerFactionInfo } from "./types";

// same name in the public/app/manifest.json  "windows"
export const WINDOW_NAMES = {
    BACKGROUND: 'background',
    DESKTOP: 'desktop',
    INGAME: 'ingame'
  }
  
  export const VERSION = '3.2.0'
  export const CLOUDFRONT_URL = "https://d27hxndnm3otog.cloudfront.net/luminite"

  export const NUM_FACTIONS = 2;


  export enum GAME_ELEMENT_TYPE {
    Unit = 'Unit',
    Structure = 'Structure'
  }

  export enum FACTION_CHOICES {
    All = 'All',
    Vanguard = 'Vanguard',
    Infernal = 'Infernal',
  }

  export enum FACTION_COLORS {
    All = '#cfcfcf',
    Vanguard = '#0c74c8',
    Infernal = '#ca3b1d',
  }

  export enum FACTION_FULL_NAMES {
    All = 'All Factions',
    Vanguard = 'Human Vanguard',
    Infernal = 'Infernal Host',
  }


  export enum GAME_MODES {
    ONE_VS_ONE = '1v1',
    THREE_VS_AI = '3VE'
  }

  export enum LEAGUES {
    MASTER = 'Master',
    DIAMOND = 'Diamond',
    PLATINUM = 'Platinum',
    GOLD = 'Gold',
    SILVER = 'Silver',
    BRONZE = 'Bronze',
    ASPIRANT = 'Aspirant',
    UNRANKED = 'Unranked'
  }

  export function apiFactionToFaction(apiFaction : string | undefined) {
    if (!apiFaction) {
      return null
    }
    if (apiFaction.toLowerCase() == 'infernals')
        return FACTION_CHOICES.Infernal

    if (apiFaction.toLowerCase() == 'vanguard')
        return FACTION_CHOICES.Vanguard

    return null
  }

export function getKeyByValue(enumObj : any, value : any) {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
}

export function playerPath(info : PlayerFactionInfo) {
  return `/player/${info.player_id}-${info.nickname}`
}