import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';

import {useEffect, Suspense} from "react";
import {WINDOW_NAMES} from "./utils/consts";
import {ow_getCurrentWindow} from "./utils/ow_utils";
import {useMainStore} from "./state"
import {CurrentWindow} from "./windows/CurrentWindow"
import React from 'react';
import { Loader, MantineColorsTuple, MantineProvider, Tooltip, createTheme } from "@mantine/core";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from "axios";
import { AuthProvider } from "./components/AuthProvider";
import { Notifications } from '@mantine/notifications';
import { RingLoader } from './components/RingLoader';


const myColor: MantineColorsTuple = [
  '#dfffff',
  '#ccfcfc',
  '#a0f6f6',
  '#71f0f0',
  '#49ebeb',
  '#2fe8e8',
  '#12e7e7',
  '#00cece',
  '#00b7b7',
  '#009e9f',
  '#1b343d'
];

const darkColors: MantineColorsTuple = [
  "#EEEEEE","#b8b8b8","#828282","#626262","#1d1e30","#1C1D2B","#181925","#14141F","#14141a","#0F0F0F"
]
  

const theme = createTheme({
  colors: {
    primary: myColor,
    dark: darkColors,
    Vanguard: [
      "#e7f7ff",
      "#d2eafc",
      "#a4d2f6",
      "#73b9f2",
      "#4da4ef",
      "#3796ed",
      "#2990ed",
      "#1c7cd3",
      "#0b6ebe",
      "#005fa9"
    ],
    Infernal: [
        "#ffeee8",
        "#fcdbd4",
        "#f3b5a8",
        "#eb8d79",
        "#e46a51",
        "#e05437",
        "#df4929",
        "#c63a1c",
        "#b13117",
        "#9c2711"
    ]
  },
  components: {
    Tooltip: Tooltip.extend({
      defaultProps: {
        color: "gray.7"
      }
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: 'ring',
      },
    }),
  },
  primaryColor: 'primary',
  headings: {
    fontFamily:"Teko, sans-serif"
  },
  scale:1.1
});

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// Create a client
const queryClient = new QueryClient();



export default function App() {
  const isOW = useMainStore(store => store.isOverwolf)
  const setActiveWindow = useMainStore(store => store.setActiveWindow)
  const activeWindow = useMainStore(store => store.activeWindow)

  
  // App initialization
  useEffect(() => {
    async function preLoad() {
      if (!isOW) {
          setActiveWindow(WINDOW_NAMES.DESKTOP)
      } else {
        const currentWindow = await ow_getCurrentWindow()
        setActiveWindow(currentWindow)
        console.info(
          '[App.tsx][useEffect - preLoad]',
          JSON.stringify({ currentWindow }, null, 2),
        )
      }
    }
    preLoad()

    // Scroll listener
  }, [])

  return (<MantineProvider theme={theme} defaultColorScheme="dark">
    
    <Suspense fallback={<>Loading...</>}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Notifications />
          {activeWindow && <CurrentWindow window={activeWindow}></CurrentWindow>}
        </AuthProvider>
        </QueryClientProvider>
    </Suspense>
    </MantineProvider>

  );
}
