import { create } from 'zustand'

export const useMainStore = create((set:any) => ({

    // Big time state
    isOverwolf: typeof(overwolf) != 'undefined',
    // serverUrl: ((typeof(overwolf) == 'undefined') && (window.location.hostname.includes('www.'))) ? 'https://www.solokill.gg' : 'https://solokill.gg',
    // serverUrl: 'http://localhost:5005', 

    // Window
    activeWindow:undefined,
    setActiveWindow: (newActiveWindow:string) => {
        return set({activeWindow:newActiveWindow})
    },
}))